export function getDefaultFieldsObj(allPersistedFields, scType) {
  const initialFields = {
    oceanMode                : null,
    autoFlightMode           : null,
    enableIgnitionDetection  : null,
    zMicroDetection          : null,
    flashFallback            : null,
    pressureSensorFlightMode : null,
    userButtonBehaviour      : '',
    userLedState             : '',
  
    wakeupPeriod            : '',
    timerAccuracy           : '',
    moveWakeupPeriod        : '',
    movementAccuracy        : '',
    immediateMovementReport : null,
    ignitionOnWakeupPeriod  : '',
    ignitionOnAccuracy      : '',
  
    // Sensors
    lightLevelAlert        : null,
    minLightLevel          : '',
    maxLightLevel          : '',
    temperatureAlert       : null,
    minTemperature         : '',
    maxTemperature         : '',
    impactAlert            : null,
    maxImpact              : '',
    tipAlert               : null,
    maxTip                 : '',
    temperatureLogging     : null,
    sensorLoggingPeriod    : '',
    syncSensorLog          : null,
    syncSensorLogTempLimit : '',
    sensorEventMode        : null,
  
    //WIFI
    wifiNetworks : {networks: []},
  
    // Advance
    // 1. Controls
    enableMovementDetection  : null,
    alwaysOn                 : null,
    disableGPS               : null,
    disableUnit              : null,
    stationaryReportingDelay : '',

    // 2. Tag Detection
    zMicroRxPeriod         : '',
    zMicroRxTime           : '',
    zMicroDeviceOldTime    : '',
    zMicroDeviceMinRSSINew : '',

    // 3. Sensors
    vehicleInput1Alert : null,
    minVehicleInput1   : '',
    maxVehicleInput1   : '',
    vehicleInput2Alert : null,
  
    // 4. Stationary
    logFullGps          : null,
    logSendOnFullBuffer : null,
    logCollectWifi      : null,
    logCollectCell      : null,
    loggingPeriod       : '',

    // 4. Moving
    movingLogFullGps          : null,
    movingLogSendOnFullBuffer : null,
    movingLogCollectWifi      : null,
    movingLogCollectCell      : null,
    movingLowLatencyReporting : null,
    movingLoggingPeriod       : '',

    // 5. Ignition
    ignitionLogFullGps          : null,
    ignitionLogSendOnFullBuffer : null,
    ignitionLogCollectWifi      : null,
    ignitionLogCollectCell      : null,
    ignitionLoggingPeriod       : ''
  };
  
  if (scType === 'SC1004') {
    const notExistFields = [
      'enableIgnitionDetection',
      'userButtonBehaviour',
      'userLedState',
      'ignitionOnWakeupPeriod',
      'ignitionOnAccuracy',
      'disableUnit',
      'vehicleInput1Alert',
      'minVehicleInput1',
      'maxVehicleInput1',
      'vehicleInput2Alert',
      'movingLowLatencyReporting',
      'ignitionLogFullGps',
      'ignitionLogSendOnFullBuffer',
      'ignitionLogCollectWifi',
      'ignitionLogCollectCell',
      'ignitionLoggingPeriod'
    ];
    notExistFields.forEach(e => delete initialFields[e]);
  }
  
  if (scType === 'SC1004V' || scType === 'SC1004v') {
    const notExistFields = [
      'userButtonBehaviour',
      'userLedState',
      'disableUnit',
      'vehicleInput1Alert',
      'minVehicleInput1',
      'maxVehicleInput1',
      'vehicleInput2Alert',
      'movingLowLatencyReporting'
    ];
    notExistFields.forEach(e => delete initialFields[e]);
  }
  
  if (scType === 'SC1100' || scType === 'SC1102') {
    const notExistFields = [
      'enableIgnitionDetection',
      'zMicroDetection',
      'flashFallback',
      'pressureSensorFlightMode',
      'userButtonBehaviour',
      'userLedState',
      'ignitionOnWakeupPeriod',
      'ignitionOnAccuracy',
      'lightLevelAlert',
      'minLightLevel',
      'maxLightLevel',
      'temperatureAlert',
      'minTemperature',
      'maxTemperature',
      'impactAlert',
      'maxImpact',
      'tipAlert',
      'maxTip',
      'wifiNetworks',
      'disableUnit',
      'disableGPS',
      'vehicleInput1Alert',
      'vehicleInput2Alert',
      'minVehicleInput1',
      'maxVehicleInput1',
      'logFullGps',
      'logSendOnFullBuffer',
      'logCollectWifi',
      'logCollectCell',
      'loggingPeriod',
      'movingLoggingPeriod',
      'movingLogFullGps',
      'movingLogSendOnFullBuffer',
      'movingLogCollectWifi',
      'movingLogCollectCell',
      'movingLowLatencyReporting',
      'ignitionLoggingPeriod',
      'ignitionLogFullGps',
      'ignitionLogSendOnFullBuffer',
      'ignitionLogCollectWifi',
      'ignitionLogCollectCell'
    ];
    notExistFields.forEach(e => delete initialFields[e]);
  }
  
  if (scType === 'SC1204') {
    const notExistFields = [
      'enableIgnitionDetection',
      'userButtonBehaviour',
      'userLedState',
      'ignitionOnWakeupPeriod',
      'ignitionOnAccuracy',
      'movingLowLatencyReporting',
      'disableUnit',
      'vehicleInput1Alert',
      'vehicleInput2Alert',
      'minVehicleInput1',
      'maxVehicleInput1',
      'ignitionLoggingPeriod',
      'ignitionLogFullGps',
      'ignitionLogSendOnFullBuffer',
      'ignitionLogCollectWifi',
      'ignitionLogCollectCell'
    ];
    notExistFields.forEach(e => delete initialFields[e]);
  }
  
  if (scType === 'SC1204S') {
    const notExistFields = [
      'enableIgnitionDetection',
      'userButtonBehaviour',
      'userLedState',
      'ignitionOnWakeupPeriod',
      'ignitionOnAccuracy',
      'movingLowLatencyReporting',
      'disableUnit',
      'vehicleInput1Alert',
      'vehicleInput2Alert',
      'minVehicleInput1',
      'maxVehicleInput1',
      'ignitionLoggingPeriod',
      'ignitionLogFullGps',
      'ignitionLogSendOnFullBuffer',
      'ignitionLogCollectWifi',
      'ignitionLogCollectCell'
    ];
    notExistFields.forEach(e => delete initialFields[e]);
  };
  
  if (scType === 'SC1204V') {
    const notExistFields = [
      'userButtonBehaviour',
      'userLedState',
      'disableUnit',
      'vehicleInput1Alert',
      'vehicleInput2Alert',
      'minVehicleInput1',
      'maxVehicleInput1',
      'movingLowLatencyReporting'
    ];
    notExistFields.forEach(e => delete initialFields[e]);
  };
  
  if (scType === 'SC1300' || scType === 'SC1302') {
    const notExistFields = [
      'enableIgnitionDetection',
      'zMicroDetection',
      'flashFallback',
      'pressureSensorFlightMode',
      'userButtonBehaviour',
      'userLedState',
      'ignitionOnWakeupPeriod',
      'ignitionOnAccuracy',
      'lightLevelAlert',
      'temperatureAlert',
      'impactAlert',
      'minLightLevel',
      'maxLightLevel',
      'minTemperature',
      'maxTemperature',
      'maxImpact',
      'tipAlert',
      'maxTip',
      'zMicroPeakDetection',
      'zMicroDeviceOldTime',
      'zMicroDeviceMinRSSINew',
      'vehicleInput1Alert',
      'vehicleInput2Alert',
      'minVehicleInput1',
      'maxVehicleInput1',
      'logFullGps',
      'logSendOnFullBuffer',
      'logCollectWifi',
      'logCollectCell',
      'loggingPeriod',
      'movingLoggingPeriod',
      'movingLogFullGps',
      'movingLogSendOnFullBuffer',
      'movingLogCollectWifi',
      'movingLogCollectCell',
      'movingLowLatencyReporting',
      'ignitionLoggingPeriod',
      'ignitionLogFullGps',
      'ignitionLogSendOnFullBuffer',
      'ignitionLogCollectWifi',
      'ignitionLogCollectCell'
    ];
    notExistFields.forEach(e => delete initialFields[e]);
  };
  
  if (scType === 'STAG09XT') {
    const notExistFields = [
      'oceanMode',
      'autoFlightMode',
      'enableIgnitionDetection',
      'zMicroDetection',
      'flashFallback',
      'pressureSensorFlightMode',
      'userButtonBehaviour',
      'userLedState',
      'wakeupPeriod',
      'timerAccuracy',
      'moveWakeupPeriod',
      'movementAccuracy',
      'immediateMovementReport',
      'ignitionOnWakeupPeriod',
      'ignitionOnAccuracy',
      'syncSensorLog',
      'syncSensorLogTempLimit',
      'lightLevelAlert',
      'temperatureAlert',
      'impactAlert',
      'minLightLevel',
      'maxLightLevel',
      'minTemperature',
      'maxTemperature',
      'maxImpact',
      'tipAlert',
      'maxTip',
      'wifiNetworks',
      'enableMovementDetection',
      'alwaysOn',
      'stationaryReportingDelay',
      'disableUnit',
      'disableGPS',
      'zMicroDeviceOldTime',
      'zMicroDeviceMinRSSINew',
      'zMicroPeakDetection',
      'vehicleInput1Alert',
      'vehicleInput2Alert',
      'minVehicleInput1',
      'maxVehicleInput1',
      'logFullGps',
      'logSendOnFullBuffer',
      'logCollectWifi',
      'logCollectCell',
      'loggingPeriod',
      'movingLoggingPeriod',
      'movingLogFullGps',
      'movingLogSendOnFullBuffer',
      'movingLogCollectWifi',
      'movingLogCollectCell',
      'movingLowLatencyReporting',
      'ignitionLoggingPeriod',
      'ignitionLogFullGps',
      'ignitionLogSendOnFullBuffer',
      'ignitionLogCollectWifi',
      'ignitionLogCollectCell'
    ];
    notExistFields.forEach(e => delete initialFields[e]);
  };
  
  const {
    // Controls
    oceanMode,
    autoFlightMode,
    enableIgnitionDetection = undefined,
    zMicroDetection = undefined,
    flashFallback = undefined,
    pressureSensorFlightMode = undefined,
    userButtonBehaviour = undefined,
    userLedState = undefined,
  
    wakeupPeriod,
    timerAccuracy,
    //  Moving
    moveWakeupPeriod,
    movementAccuracy,
    immediateMovementReport,
    // ignition
    ignitionOnWakeupPeriod = undefined,
    ignitionOnAccuracy = undefined,
  
    // Sensors
    lightLevelAlert = undefined,
    minLightLevel = undefined,
    maxLightLevel = undefined,
    temperatureAlert = undefined,
    minTemperature = undefined,
    maxTemperature = undefined,
    impactAlert = undefined,
    maxImpact = undefined,
    tipAlert = undefined,
    maxTip = undefined,
    temperatureLogging = undefined,
    sensorLoggingPeriod = undefined,
    syncSensorLog = undefined,
    syncSensorLogTempLimit = undefined,
    sensorEventMode = undefined,
  
    //WIFI
    wifiNetworks = undefined,
  
    // Advance
    // 1. Controls
    enableMovementDetection = undefined,
    alwaysOn = undefined,
    disableGPS = undefined,
    disableUnit = undefined,
    stationaryReportingDelay = undefined,
    // 2. Tag Detection
    zMicroRxPeriod,
    zMicroRxTime,
    zMicroDeviceOldTime,
    zMicroDeviceMinRSSINew,
    // 3. Sensors
    vehicleInput1Alert = undefined,
    minVehicleInput1 = undefined,
    maxVehicleInput1 = undefined,
    vehicleInput2Alert = undefined,
  
    // 4. Stationary
    logFullGps = undefined,
    logSendOnFullBuffer = undefined,
    logCollectWifi = undefined,
    logCollectCell = undefined,
    loggingPeriod = undefined,
    // 4. Moving
    movingLogFullGps = undefined,
    movingLogSendOnFullBuffer = undefined,
    movingLogCollectWifi = undefined,
    movingLogCollectCell = undefined,
    movingLowLatencyReporting = undefined,
    movingLoggingPeriod = undefined,
    // 5. Ignition
    ignitionLogFullGps = undefined,
    ignitionLogSendOnFullBuffer = undefined,
    ignitionLogCollectWifi = undefined,
    ignitionLogCollectCell = undefined,
    ignitionLoggingPeriod = undefined
  } = Object.keys(allPersistedFields).length !== 0 ? allPersistedFields : initialFields;
  
  const controls = [
    ...(
      (oceanMode !== undefined)
        ? [{
          label : 'Ocean Mode',
          oceanMode
        }] : [{}]
    ),
    ...(
      (autoFlightMode !== undefined)
        ? [{
          label : 'Flight Mode',
          autoFlightMode
        }] : [{}]
    ),
    ...(
      (enableIgnitionDetection !== undefined)
        ? [{
          label : 'Ignition Detection',
          enableIgnitionDetection
        }] : [{}]
    ),
    ...(
      (zMicroDetection !== undefined)
        ? [{
          label : 'Tag Detection',
          zMicroDetection
        }] : [{}]
    ),
    ...(
      (flashFallback !== undefined)
        ? [{
          label : 'Store & Retry',
          flashFallback
        }] : [{}]
    ),
    ...(
      (pressureSensorFlightMode !== undefined)
        ? [{
          label : 'Pressure Sensor Flight Mode',
          pressureSensorFlightMode
        }] : [{}]
    ),
    ...(
      (userButtonBehaviour !== undefined)
        ? [{
          label : 'Button Behavior',
          userButtonBehaviour
        }] : [{}]
    ),
    ...(
      (userLedState !== undefined)
        ? [{
          label : 'LED Behavior',
          userLedState
        }] : [{}]
    )
  ];
  
  const reportAndLogs = [
    [
      ...(
        (wakeupPeriod !== undefined)
          ? [{
            label : 'Reporting Interval',
            wakeupPeriod
          }] : [{}]
      ),
      ...(
        (timerAccuracy !== undefined)
          ? [{
            label : 'Accuracy',
            timerAccuracy
          }] : [{}]
      )
    ],
    [
      ...(
        (moveWakeupPeriod !== undefined)
          ? [{
            label : 'Reporting Interval',
            moveWakeupPeriod
          }] : [{}]
      ),
      ...(
        (movementAccuracy !== undefined)
          ? [{
            label : 'Accuracy',
            movementAccuracy
          }] : [{}]
      ),
      ...(
        (immediateMovementReport !== undefined)
          ? [{
            label : 'Report Movement Immediately',
            immediateMovementReport
          }] : [{}]
      )
    ],
    [
      ...(
        (ignitionOnWakeupPeriod !== undefined)
          ? [{
            label : 'Reporting Interval',
            ignitionOnWakeupPeriod
          }] : [{}]
      ),
      ...(
        (ignitionOnAccuracy !== undefined)
          ? [{
            label : 'Accuracy',
            ignitionOnAccuracy
          }] : [{}]
      )
    ]
  ];
  
  const sensors = [
    ...(
      (lightLevelAlert !== undefined)
        ? [{
          label : 'Light Level Alert',
          lightLevelAlert,
          minLightLevel,
          maxLightLevel
        }] : [{}]
    ),
    ...(
      (temperatureAlert !== undefined)
        ? [{
          label : 'Temperature Alert',
          temperatureAlert,
          minTemperature,
          maxTemperature
        }] : [{}]
    ),
    ...(
      (impactAlert !== undefined)
        ? [{
          label : 'Impact Alert',
          impactAlert,
          maxImpact
        }] : [{}]
    ),
    ...(
      (tipAlert !== undefined)
        ? [{
          label : 'Tip Alert',
          tipAlert,
          maxTip
        }] : [{}]
    ),
    {
      label : 'Temperature Logging',
      temperatureLogging,
      sensorLoggingPeriod,
      syncSensorLog,
      syncSensorLogTempLimit,
      sensorEventMode
    }
  ];
    
  const wifi = {
    wifiNetworks
  };
  
  const advance = [
    [
      ...(
        (enableMovementDetection !== undefined)
          ? [{
            label : 'Movement Detection',
            enableMovementDetection
          }] : [{}]
      ),
      ...(
        (alwaysOn !== undefined)
          ? [{
            label : 'Do not unregister from network',
            alwaysOn
          }] : [{}]
      ),
      ...(
        (disableGPS !== undefined)
          ? [{
            label : 'GPS',
            disableGPS
          }] : [{}]
      ),
      ...(
        (disableUnit !== undefined)
          ? [{
            label : 'Should shut down after next report',
            disableUnit
          }] : [{}]
      ),
      ...(
        (stationaryReportingDelay !== undefined)
          ? [{
            label : 'Stationary Reporting delay',
            stationaryReportingDelay
          }] : [{}]
      )
    ],
    [
      {
        label : 'Tag Detection Mode'
      },
      {
        label : 'Receive Interval (s)',
        zMicroRxPeriod
      },
      {
        label : 'Receive Time (ms)',
        zMicroRxTime
      },
      ...(
        (zMicroDeviceOldTime !== undefined)
          ? [{
            label : 'Time to wait before device mark as missing (s)',
            zMicroDeviceOldTime
          }] : [{}]
      ),
      ...(
        (zMicroDeviceMinRSSINew !== undefined)
          ? [{
            label : 'RSSI required to detect new device (dBm)',
            zMicroDeviceMinRSSINew
          }] : [{}]
      )
    ],
    [
      ...(
        (vehicleInput1Alert !== undefined)
          ? [{
            label : 'Vehicle Input 1 Alert',
            vehicleInput1Alert,
            minVehicleInput1,
            maxVehicleInput1
          }] : [{}]
      ),
      ...(
        (vehicleInput2Alert !== undefined)
          ? [{
            label : 'Vehicle Input 2 Alert',
            vehicleInput2Alert
          }] : [{}]
      )
    ],
    [
      ...(
        (logFullGps !== undefined)
          ? [{
            label : 'Log Full GPS',
            logFullGps
          }] : [{}]
      ),
      ...(
        (logSendOnFullBuffer !== undefined)
          ? [{
            label : 'Send log on full buffer',
            logSendOnFullBuffer
          }] : [{}]
      ),
      ...(
        (logCollectWifi !== undefined)
          ? [{
            label : 'Log WIFI',
            logCollectWifi
          }] : [{}]
      ),
      ...(
        (logCollectCell !== undefined)
          ? [{
            label : 'Log Cell',
            logCollectCell
          }] : [{}]
      ),
      ...(
        (loggingPeriod !== undefined)
          ? [{
            label : 'Logging Period',
            loggingPeriod
          }] : [{}]
      )
    ],
    [
      ...(
        (movingLogFullGps !== undefined)
          ? [{
            label : 'Log Full GPS',
            movingLogFullGps
          }] : [{}]
      ),
      ...(
        (movingLogSendOnFullBuffer !== undefined)
          ? [{
            label : 'Send log on full buffer',
            movingLogSendOnFullBuffer
          }] : [{}]
      ),
      ...(
        (movingLogCollectWifi !== undefined)
          ? [{
            label : 'Log WIFI',
            movingLogCollectWifi
          }] : [{}]
      ),
      ...(
        (movingLogCollectCell !== undefined)
          ? [{
            label : 'Log Cell',
            movingLogCollectCell
          }] : [{}]
      ),
      ...(
        (movingLowLatencyReporting !== undefined)
          ? [{
            label : 'Low latency reporting (impact battery)',
            movingLowLatencyReporting
          }] : [{}]
      ),
      ...(
        (movingLoggingPeriod !== undefined)
          ? [{
            label : 'Logging Period',
            movingLoggingPeriod
          }] : [{}]
      )
    ],
    [
      ...(
        (ignitionLogFullGps !== undefined)
          ? [{
            label : 'Log Full GPS',
            ignitionLogFullGps
          }] : [{}]
      ),
      ...(
        (ignitionLogSendOnFullBuffer !== undefined)
          ? [{
            label : 'Send log on full buffer',
            ignitionLogSendOnFullBuffer
          }] : [{}]
      ),
      ...(
        (ignitionLogCollectWifi !== undefined)
          ? [{
            label : 'Log WIFI',
            ignitionLogCollectWifi
          }] : [{}]
      ),
      ...(
        (ignitionLogCollectCell !== undefined)
          ? [{
            label : 'Log Cell',
            ignitionLogCollectCell
          }] : [{}]
      ),
      ...(
        (ignitionLoggingPeriod !== undefined)
          ? [{
            label : 'Logging Period',
            ignitionLoggingPeriod
          }] : [{}]
      )
    ]
  ];

  const removeDuplicate = (arr) => {
    const result = arr?.map((each) => each?.filter(value => Object.keys(value).length !== 0));
    return result;
  };

  const controlFields = controls.filter(value => Object.keys(value).length !== 0);
  const sensorsFields = sensors.filter(value => Object.keys(value).length !== 0);
  const reportAndLogsFields = removeDuplicate(reportAndLogs);
  return [
    controlFields,
    reportAndLogsFields,
    sensorsFields,
    advance,
    wifi,
    initialFields
  ];
}
